import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import './LandingContent.styles.scss'
import farmOff from "../../images/farm-off.png";
import farmOn from "../../images/farm-on.png";
import vortexOff from "../../images/vortex-off.png";
import vortexOn from "../../images/vortex-on.png";
import gateLeft from "../../images/gate-left.svg";
import gateRight from "../../images/gate-right.svg";
import monsters from "../../images/monsters.gif";

const VRLink = 'https://oncyber.io/smallpocketsvr?fbclid=IwAR2YMBMMbOfOZVXJLnNzVNVnQ9_CABLDQU5yenGT52QwE1iFajFNAV2fKnY'

const LandingContent = ({onSetLandingFooterStyles}) => {
    const [farmImage, setFarmImage] = useState(farmOff);
    const [vortexImage, setVortexImage] = useState(vortexOff);
    const [clickedYes, setClickedYes] = useState(false);
    const [monstersAnimation, setMonstesAnimation] = useState({})
    const [monstersImgAnimation, setMonstesImgAnimation] = useState({})
    const [topContainerAnimation, setTopContainerAnimation] = useState({})
    const [topContainerGatesAnimation, setTopContainerGatesAnimation] = useState({})
    const [gateLeftAnimation, setGateLeftAnimation] = useState({})
    const [gateRightAnimation, setGateRightAnimation] = useState({})
    const [linksAnimation, setLinksAnimation] = useState({})

    const navigate = useNavigate();


    const handleOnYes = () => {
        onSetLandingFooterStyles({bottom: "-100%"})
        setClickedYes(true);
        setMonstesAnimation({ margin: "-250% 0" });
        setMonstesImgAnimation({ width: "150%" });
        setTopContainerAnimation({ height: "100%" });
        setTopContainerGatesAnimation({})
        setGateLeftAnimation({ left: "-50%" })
        setGateRightAnimation({ right: "-50%" })
        setLinksAnimation({ margin: "0%", width: "48%" })
    }

    const handleGoToHomePage = () => {
        navigate("/home");
    }

    const handleGoToSorry = () => {
        navigate("/sorry");
    }

    const handleGoToVR = () => {
        window.location.href = VRLink
    }
    return (
        <div className='landing-content'>
            <div className='top-container' style={topContainerAnimation}>
                <div className='top-container-links'>
                    <img
                        onMouseOver={() => setFarmImage(farmOn)}
                        onMouseOut={() => setFarmImage(farmOff)}
                        style={linksAnimation}
                        className={clickedYes ? 'clicked' : ''}
                        src={farmImage}
                        alt="farm"
                        onClick={handleGoToHomePage}
                    />
                    <img
                        onMouseOver={() => setVortexImage(vortexOn)}
                        onMouseOut={() => setVortexImage(vortexOff)}
                        style={linksAnimation}
                        className={clickedYes ? 'clicked' : ''}
                        src={vortexImage}
                        alt="vortex"
                        onClick={handleGoToVR}
                    />
                </div>
                <div style={topContainerGatesAnimation} className='top-container-gates'>
                    <div className='gate-container'>
                        <img style={gateLeftAnimation} className='gate-left' src={gateLeft} alt="left gate" />
                        <img style={gateRightAnimation} className='gate-right' src={gateRight} alt="right gate" />
                    </div>
                </div>
                <div
                    style={monstersAnimation}
                    className='top-container-monsters'
                >
                    <img style={monstersImgAnimation} className='monsters' src={monsters} alt="monsters" />
                    <a
                        onClick={handleOnYes}
                        className="yes"
                    />
                    <a
                        onClick={handleGoToSorry}
                        className="no"
                    />
                </div>
            </div>
        </div>
    )
}

export default LandingContent