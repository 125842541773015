import React from "react";
import "./LandingFooter.styles.scss";
import trails from "../../images/trails.jpg";
import hopGuy from "../../images/hop-guy-landing-footer.png";

const LandingFooter = ({styles}) => {

  return (
    <div style={styles} className="landing-footer">
      <div className="footer-container" >
        <div className="footer-hop-guy">
          <img src={hopGuy} alt="follow-us" />
          <a
            href="https://www.instagram.com/smallpocketsfarm/"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {""}
          </a>
        </div>
      </div>
      <div className="footer-logo">
        <img src={trails} alt="trails" />
        <p>Trails</p>
      </div>
      <div className="location-info">
        <h1>Our Location</h1>
        <p>5418 State Route 89</p>
        <p>Romulus, NY 14541</p>
      </div>
      <div className="hours-info">
        <h1>Hours</h1>
        <p>Check Google for Hours</p>
      </div>
    </div>
  );
}

export default LandingFooter;
