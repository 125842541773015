import React from 'react';
import "./Home.styles.scss";
import Header from '../header/Header.js'
import Footer from '../footer/Footer.js'
import Main from '../main/Main.js'
import { useMediaQuery } from 'react-responsive';
import video from "../../videos/spf-full-video.mp4";

import background from '../../images/animation-gif.gif'

const Home = () => {

    const bigScreen = useMediaQuery({ query: '(min-width: 1500px)' })
    const laptop = useMediaQuery({ query: '(min-width: 1024px) and (max-width: 1499px)' })
    const mobile = useMediaQuery({ query: '(max-width: 600px)' })

    const style = {};

    if (bigScreen) {
        style['maxWidth'] = '1200px'
    }
    if (laptop) {
        style['maxWidth'] = '1024px'
    }
    if (mobile) {
        style['backgroundSize'] = '100%'
        style['backgroundRepeat'] = 'no-repeat'
        style['backgroundImage'] = `url(${background})`
    }

    return (

        <div className='home' style={style}>
            {!mobile ? <video className='home-background-video' preload playsInline autoPlay loop muted>
                <source src={video} type='video/mp4' />
            </video> : null}
            {/* <video className='home-background-video' muted="true" preload="true" playsInline autoPlay loop controls>
                <source src={video} type='video/mp4' />
            </video> */}
            <div
        />

            <Header />
            <Main />
            <Footer />
        </div>
    )
}

export default Home