import React, {useState} from 'react';
import "./Landing.styles.scss";
import LandingFooter from '../footer/LandingFooter';
import LandingContent from '../landing-content/LandingContent';
import { useMediaQuery } from 'react-responsive';

const Landing = () => {
    const [landingFooterStyles, setLandingFooterStyles] = useState({})

    const bigScreen = useMediaQuery({ query: '(min-width: 1500px)' })
    const laptop = useMediaQuery({ query: '(min-width: 1024px) and (max-width: 1499px)' })
    const mobile = useMediaQuery({ query: '(max-width: 1023px)' })

    const style = {};

    if(bigScreen){
        style['width'] = '1200px'
    }
    if(laptop){
        style['width'] = '1024px'
    }
    if(mobile){
        style['width'] = '100%'
    }

    const handleSetLandingFooterStyles = (styles) => {
        setLandingFooterStyles(styles)
    }
    
    return (
        <div
            className='landing'
            style={style}
        >
            <LandingContent onSetLandingFooterStyles={handleSetLandingFooterStyles}/>
            <LandingFooter styles={landingFooterStyles}/>
        </div>
    )
}

export default Landing