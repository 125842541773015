import './App.css';
import { useEffect, useState } from 'react';
import Landing from './components/landing/Landing';
import Home from './components/home/Home';
import Oops from './components/oops/Oops';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {

  // const [hours, setHours] = useState('')

  // let fetchHours = async () => {
  //   let resp = await fetch("http://localhost:3000/hours.txt");
  //   let hours = await resp.json();

  //   setHours(hours)
  // }

  // useEffect(() => {
  //   fetchHours();
  // }, [])
  // console.log(hours);
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route exact path="/home" element={<Home />}/>
          <Route exact path="/sorry" element={<Oops/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
