import React from 'react'
import './Oops.styles.scss'
import oopsImg from '../../images/oops.png'

const Oops = () => {
  return (
    <div className='oops'>
        <img src={oopsImg} alt="oops" />
    </div>
  )
}

export default Oops