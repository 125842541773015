import React from "react";
import "./Footer.styles.scss";
import trails from "../../images/trails.jpg";

function Footer() {
  return (
    <div className="footer about-us">
      <div className="footer-logo">
        <img src={trails} alt="trails" />
        <p>Trails</p>
      </div>
      <div className="location-info">
        <h1>Our Location</h1>
        <p>5418 State Route 89</p>
        <p>Romulus, NY 14541</p>
      </div>
      <div className="hours-info">
        <h1>Hours</h1>
        <p>Check Google for Hours</p>
      </div>
    </div>
  );
}

export default Footer;
